import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Alert from '@myaccount/alert';
import ActionBtn from '../ctas/actionBtn';
import ContactBtn from '../ctas/contact';

const ActionsDialogIndex = (props) => {
    const {
        actionCardDialogChildrenStyles,
        actionItemDefaults,
        // roleType,
        date,
        title,
        step,
        data,
        userRole,
        // ...rest
    } = props

    const { state } = useFormState()

    const negotiatorImg = '/broken-image.jpg';
    const negotiatorName = 'James Patterson · Adviser';
    const negotiatorMessage = '';
    let c = 0;

    const renderActions = () => {

        const actionParams = actionItemDefaults[step];

        return actionParams.map((btn) => {
            const btnParams = btn?.btnParams || {};
            switch (btn.name) {
                case 'accept':
                case 'reject':
                case 'withdrawn':
                    return (
                        <ActionBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            btnParams={btnParams}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                case 'contact':
                    return (
                        <ContactBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                // @todo later reject, withdrawn may come
                default:
                    return (
                        <ContactBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )

            }

        })
    }

    return(
        <Box>
            <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                <Avatar src={data.image} alt="Tenancy" classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                {date && (
                    <Typography {...actionCardDialogChildrenStyles.actionChildrenSubTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                        {date}
                    </Typography>
                )}
            </Box>
            <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error' : 'success'} />
            {(!state.success || state.error) && (
                <>
                <Box sx={actionCardDialogChildrenStyles.actionChildrenButtonsSx}>
                    <>
                        {renderActions()}
                    </>
                </Box>
                {negotiatorMessage && (
                    <Box sx={actionCardDialogChildrenStyles.actionChildrenAdviserSx}>
                        <Avatar src={negotiatorImg} alt={negotiatorName} classes={{root: "actionCardDialogChildren-avatarAdviser"}} />
                        <Paper {...actionCardDialogChildrenStyles.actionChildrenCommentProps} classes={{root: "actionCardDialogChildren-commentBox"}}>
                            <Typography {...actionCardDialogChildrenStyles.actionChildrenCommentProps.text} >{negotiatorMessage}</Typography>
                            <Typography {...actionCardDialogChildrenStyles.actionChildrenCommentProps.author}>{negotiatorName}</Typography>
                        </Paper>
                    </Box>
                )}
                </>
            )}
        </Box>
    )
}

export default ActionsDialogIndex;
