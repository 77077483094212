export default {
    "props": {
        "showActionIcon": true,
        "showActionButton": true,
        "card": {
            // "variant": "outlined",
            "elevation": 0,
        },
        "cardAction": {
            "color": "primary",
        },
        "cardActionButton": {
            "color": "inherit",
        },
        "cardHeader": {
            "titleTypographyProps": {
                "variant": "h3",
                "component": "h3",
            },
            "subheaderTypographyProps": {
                "variant": "caption",
            },
        },
    },
    "sx": (theme) => ({
        "&.actionCard-card": {
            "marginBottom": 0,
            "paddingTop": theme.spacing(1),
            "paddingBottom": theme.spacing(1),
            "&.disabled": {
                "opacity": 0.5
            },
            "&.other-bidder": {
                "opacity": 0.5
            }
        },
        "& .actionCard-cardActionButton": {
            "backgroundColor": theme.palette.grey.grey4,
            "color": theme.palette.primary.dark,
        },
        "& .actionCard-cardHeader": {
            "padding": theme.spacing(1,0),
            [theme.breakpoints.up('sm')]: {
                "padding": theme.spacing(1.5,0),
            },
            "& .cardHeader-avatar": {
                "marginLeft": "auto",
                "marginRight": theme.spacing(1.875),
                [theme.breakpoints.up('sm')]: {
                    "marginRight": theme.spacing(3.5),
                }
            },
            "& .cardHeader-title": {
                "fontSize": theme.bodySmall,
                "marginBottom": theme.spacing(0.25),
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.bodyLarge,
                    "lineHeight": theme.lineHeightBLarge,
                    "marginBottom": theme.spacing(0.5),
                }
            },
            "& .cardHeader-subheader": {
                "color": "#6A6A6A",
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                }
            },
            "& .cardHeader-action": {
                "alignSelf": "center",
                "marginRight": theme.spacing(0.5),
            }
        },
    })
}
