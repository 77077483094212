export default {
    "props": {
        "actionItemDefaults": {
            owner: {
                "default": [
                    {
                        type: "cta",
                        name: "accept",
                        crm_value: "accepted",
                        label: "Accept",
                        // nextStep: "actionAcceptPostMessage",
                        btnParams: {
                            status:'accepted',
                            role: 'owner',
                            form_name:'offer_accepted',
                            successMessage: "Offer Accepted.",
                        }
                    },
                    {
                        type: "cta",
                        name: "reject",
                        crm_value: "rejected",
                        label: "Reject",
                        // nextStep: "actionRejectPostMessage",
                        btnParams: {
                            status:'rejected',
                            role: 'owner',
                            form_name:'offer_rejected',
                            successMessage: "Offer Rejected.",
                        },
                    },
                    /*{
                        type: "cta",
                        name: "contact",
                        label: "Contact Us",
                        role: 'owner',
                        nextStep: "actionContactMessage",
                    }*/
                ],
                "actionAcceptPostMessage": {
                    formParams: {
                        form_name:'offer_accepted_message',
                        role: 'owner',
                        status: 'accepted'
                    }
                },
                "actionRejectPostMessage": {
                    formParams: {
                        form_name:'offer_rejected_message',
                        role: 'owner',
                        status: 'rejected'
                    }
                },
                "actionContactMessage": {
                    formParams: {
                        status:'pending',
                        role: 'owner',
                        form_name:'offer_message'
                    }
                },
            },
            applicant: {
                "default": [
                    {
                        type: "cta",
                        name: "withdrawn",
                        crm_value: "withdrawn",
                        label: "Withdraw",
                        // nextStep: "actionWithdrawnPostMessage",
                        btnParams: {
                            status:'withdrawn',
                            role: 'applicant',
                            form_name:'offer_withdrawn',
                            successMessage: "Offer Withdrawn.",
                        }
                    },
                    {
                        type: "cta",
                        name: "revise_offer",
                        label: "Revise Offer",
                        nextStep: "actionReviseOffer",
                        nextStepParams: {
                            role: 'applicant',
                            form_name:'revise_offer'
                        }
                    },
                    /*{
                        type: "cta",
                        name: "contact",
                        role: 'applicant',
                        label: "Contact Us",
                        nextStep: "actionContactMessage",
                    }*/
                ],
                "actionReviseOffer": {
                    formParams: {
                        status:'pending',
                        role: 'applicant',
                        form_name:'revise_offer'
                    }
                },
                "actionWithdrawnPostMessage": {
                    formParams: {
                        status:'withdrawn',
                        role: 'applicant',
                        form_name:'offer_withdrawn_message'
                    }
                },
                "actionContactMessage": {
                    formParams: {
                        status:'pending',
                        role: 'applicant',
                        form_name:'offer_message'
                    }
                }
            }
        }
    }
}
