import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Alert from '@myaccount/alert';
import GlobalForm from "@myaccount/forms/"
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils"
import reviseOfferFields from "@myaccount/forms/Forms/reviseOffer"

const ActionsDialogReviseOffer = (props) => {
    const {
        actionCardDialogChildrenStyles,
        actionItemDefaults,
        // roleType,
        // date,
        title,
        step,
        data,
        // ...rest
    } = props

    const { state, services } = useFormState()

    const actionParams = actionItemDefaults[step]

    // re organise the fields values with the new values
    let customFields = [...reviseOfferFields];
    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message: "", severity: "" }
    const formData = { submitLabel: "Revise Offer" }
    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);

        if (ref.current.checkValidity() !== false) {
            const params = {
                offer_id: data.crm_id,
                form_name: "revise_offer",
                step: step,
                successMessage: "Revised Offer Posted Successfully",
                ...actionParams.formParams,
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <Box>
            <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                <Avatar src={data.image} alt={title} classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error' : 'success'} />
                {(!state.success || state.error) && (
                    <GlobalForm
                        formFields={formFields}
                        handleSubmit={onSubmit}
                        formStateType={formStateType}
                        messageDialog={messageDialog}
                        data={formData}
                        ref={ref}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ActionsDialogReviseOffer;
