import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Alert from '@myaccount/alert';
import ActionBtn from '../ctas/actionBtn';
import ContactBtn from '../ctas/contact';
import { displayData, timeFromNow } from "@myaccount/helper/dateformat"

const ActionsDialogIndex = (props) => {
    const {
        actionCardDialogChildrenStyles,
        actionItemDefaults,
        roleType,
        date,
        title,
        step,
        data,
        userRole,
        // ...rest
    } = props

    const { state } = useFormState()

    const offer_notes = data?.offer_notes || [];
    let c = 0;

    const renderActions = () => {

        const actionParams = actionItemDefaults[step];

        return actionParams.map((btn) => {
            const btnParams = btn?.btnParams || {};

            // make status lowercase if it is upper case
            if (data?.offer_status) {
                data.offer_status = data.offer_status.toLowerCase();
            }

            // persona logic here
            if (roleType === 'applicant' && data?.offer_status === 'rejected' && (btn.name === 'withdrawn')) {
                return false;
            } else if (roleType === 'applicant' && data?.offer_status === 'accepted' && (btn.name === 'reject' || btn.name === 'withdrawn')) {
                return false;
            } else if (roleType === 'applicant' && data?.offer_status === 'withdrawn' && (btn.name === 'withdrawn')) {
                return false;
            } else if (roleType === 'applicant' && (data?.offer_status !== 'pending' && data?.offer_status !== 'noteofinterest') && (btn.name === 'revise_offer')) {
                return false;
            } else if (roleType === 'owner' && data?.offer_status === 'accepted' && (btn.name === 'accept' || btn.name === 'reject')) {
                return false;
            } else if (roleType === 'owner' && data?.offer_status === 'withdrawn' && (btn.name === 'reject' || btn.name === 'accept')) {
                return false;
            } else if (roleType === 'owner' && data?.offer_status === 'rejected' && (btn.name === 'reject' || btn.name === 'accept')) {
                return false;
            }

            switch (btn.name) {
                case 'accept':
                case 'reject':
                case 'withdrawn':
                    return (
                        <ActionBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            btnParams={btnParams}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                case 'contact':
                case 'revise_offer':
                    return (
                        <ContactBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                // @todo later reject, withdrawn may come
                default:
                    return (
                        <ContactBtn
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )

            }

        })
    }

    const RenserOfferNotes = () => {
        const offer_notes = data?.offer_notes || [];
        if (!offer_notes.length)
            return <></>

        return (
            <>
                {offer_notes.map((notes) => {
                    let negotiator_info = notes.negotiator_info;
                    const negotiatorImg = negotiator_info?.profile_img || '/broken-image.jpg';
                    const negotiatorName = notes?.negotiator_info?.name || 'N';
                    let negotiatorDetails = notes?.negotiator_info?.name;
                    if (notes?.negotiator_info?.job_title)
                        negotiatorDetails += ` - ${notes?.negotiator_info?.job_title}`
                    return (
                        <Box sx={actionCardDialogChildrenStyles.actionChildrenAdviserSx}>
                            <Avatar src={negotiatorImg} alt={negotiatorName} classes={{root: "actionCardDialogChildren-avatarAdviser"}} />
                            <Paper {...actionCardDialogChildrenStyles.actionChildrenCommentProps} classes={{root: "actionCardDialogChildren-commentBox"}}>
                                <Typography {...actionCardDialogChildrenStyles.actionChildrenCommentProps.text} >{displayData(notes.date)} - {notes.description}</Typography>
                                <Typography {...actionCardDialogChildrenStyles.actionChildrenCommentProps.author}>{`${negotiatorDetails}`}</Typography>
                            </Paper>
                        </Box>
                    )
                })}
            </>
        )
    }

    return(
        <Box>
            <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                <Avatar src={data.image} alt="Offer" classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                {date && (
                    <Typography {...actionCardDialogChildrenStyles.actionChildrenSubTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                        {date}
                    </Typography>
                )}
            </Box>
            <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error' : 'success'} />
            {(!state.success || state.error) && (
                <>
                <Box sx={actionCardDialogChildrenStyles.actionChildrenButtonsSx}>
                    <>
                        {renderActions()}
                    </>
                </Box>
                    <RenserOfferNotes />
                </>
            )}
        </Box>
    )
}

export default ActionsDialogIndex;
