export default {
    "props": {
        "actionItemDefaults": {
            owner: {
                "default": [
                    {
                        type: "cta",
                        name: "accept",
                        crm_value: "accepted",
                        label: "Accept",
                        btnParams: {
                            status:'arranging',
                            role: 'owner',
                            form_name:'tenancy_arranging',
                            successMessage: "Tenancy Offer Accepted Successfully",
                        }
                    },
                    {
                        type: "cta",
                        name: "reject",
                        crm_value: "rejected",
                        label: "Reject",
                        btnParams: {
                            status:'offerRejected',
                            role: 'owner',
                            form_name:'tenancy_rejected',
                            successMessage: "Tenancy Offer Rejected.",
                        },
                    },
                    /*{
                        type: "cta",
                        name: "contact",
                        role: 'owner',
                        label: "Contact Us",
                        nextStep: "actionContactMessage",
                    }*/
                ],
                "actionContactMessage": {
                    formParams: {
                        role: 'owner',
                        form_name:'tenancy_message'
                    }
                },
            },
            applicant: {
                "default": [
                    {
                        type: "cta",
                        name: "withdrawn",
                        crm_value: "withdrawn",
                        label: "Withdraw",
                        btnParams: {
                            role: 'applicant',
                            status:'offerWithdrawn',
                            form_name:'tenancy_withdrawn',
                            successMessage: "Tenancy Offer Withdrawn.",
                        }
                    },
                    {
                        type: "cta",
                        name: "contact",
                        role: 'applicant',
                        label: "Contact Us",
                        nextStep: "actionContactMessage",
                    }
                ],
                "actionContactMessage": {
                    formParams: {
                        role: 'applicant',
                        form_name:'tenancy_message'
                    }
                }
            }
        }
    }
}
