import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Alert from '@myaccount/alert';
import ActionBtn from '../ctas/actionBtn';
import DownloadBtn from '../ctas/download';

const ActionsDialogIndex = (props) => {
    const {
        actionCardDialogChildrenStyles,
        actionItemDefaults,
        roleType,
        date,
        title,
        step,
        data,
        userRole,
        useApiDownload,
        // ...rest
    } = props

    const { state } = useFormState()
    let c = 0;

    const renderActions = () => {

        const actionParams = actionItemDefaults[step];

        return actionParams.map((btn) => {

            // persona logic here
            if (roleType === 'owner' && data?.property_confirmed && btn.name === 'confirm') {
                return false;
            } else if (roleType === 'applicant' && data?.attendee_confirmed && btn.name === 'confirm') {
                return false;
            } else if (roleType === 'applicant' && !data?.attendee_confirmed && btn.name === 'cancel') {
                return false;
            }

            const btnParams = btn?.btnParams || {};
            switch (btn.name) {
                case 'download':
                    return (
                        <DownloadBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            btnParams={btnParams}
                            step={step}
                            data={data}
                            label={btn.label}
                            useApiDownload={useApiDownload}
                            userRole={userRole}
                        />
                    )
                default:
                    return (
                        <ActionBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            btnParams={btnParams}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )

            }

        })
    }

    return(
        <Box>
            <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                <Avatar src={data.image} alt="Document" classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                {date && (
                    <Typography {...actionCardDialogChildrenStyles.actionChildrenSubTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                        {date}
                    </Typography>
                )}
            </Box>
            <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error' : 'success'} />
            {(!state.success || state.error) && (
                <Box sx={actionCardDialogChildrenStyles.actionChildrenButtonsSx}>
                    <>
                        {renderActions()}
                    </>
                </Box>
            )}
        </Box>
    )
}

export default ActionsDialogIndex;
