import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { timeFromNow, displayData } from "@myaccount/helper/dateformat"
import { useFormState, usePropertyActionState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const RenderDate = (data) => {
    return (
        <>
            Status: {data.status} {data.booked_date && (
                <>
                    &middot; {displayData(data.booked_date)}
                </>
            )}
        </>
    )
}

const ActionDefault = withSubtheme(({data, type, ...rest}) => {

    const { state:formState, services:formServices } = useFormState()
    const { services:actionServices } = usePropertyActionState();

    let status = {}
    const itemData = {...data, ...status}
    // let requiredDate = data?.required_date ? ` - Required on ${data.required_date}` : ``;
    // if (data.status === 'complete')
    //     requiredDate = data?.required_date ? ` - Completed on ${data.completed_date}` : ``;

    const title = data.title; // || <ActionTitle data={data} />

    let date = '';
    if (rest?.timesAgo)
        date = timeFromNow(data.createdAt)
    else
        date = <RenderDate {...data} />

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:type})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
        // remove work order from open_items and add it in to closed_items
        if (formState?.data?.postAction === 'remove') {
            actionServices.updatePropertyStatus({...formState.data, key:'worksOrders.open_items'});
            actionServices.updatePropertyStatus({...formState.data, key:'worksOrders.closed_items', postAction:'add'});
        }
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let showActionButton = true;
    if (data?.completed || data?.cancelled)
        showActionButton = false;
    if (data.hasOwnProperty('showActionButton'))
        showActionButton = data.showActionButton

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            type={type}
            title={title}
            date={date}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="worksOrderIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={{...data,  showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardWorksorder', defaults)

export default ActionDefault;
